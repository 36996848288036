// BTOCSITE-1814 모바일 GNB 멤버십/이벤트 탭 추가 :  신규작성 start
@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";

// BTOCSITE-47780 닷컴 이벤트&기획전 통합
@import "../../../../lg5-common/front/pages/UTS/css/swiper-8.2.4.min.css";

// common

@import "../../components/KRC0015";
@import "../../components/KRP0039";
@import "../../components/KRC0032-html";

.event-membership {
    border-top: 1px solid #ddd;
    .slide-wrap.hero-banner .slide-content .slide-track, .slide-wrap.hero-banner .slide-content .slide-conts {
        // height: 480px;  // BTOCSITE-47780 닷컴 이벤트&기획전 통합  // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청

        //BTOCSITE-7335 -start
        position:relative;
        .slide-img {
            overflow:hidden;
            position: relative;
            width:100%;
            height:100%;
            img {
                position:absolute;
                top:0;
                left:50%;
                transform:translateX(-50%);
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
                max-width:none;
                // width:auto;
                // max-width:100%;
                // height:100%;

                @media screen and (min-width:1920px) {
                    width:100%;
                    height:auto;
                    top:50%;
                    left:0;
                    transform:translateY(-50%);
                }

                @include mobile {
                    width:100%;
                    height:auto;
                    top:auto;
                    bottom: 0;
                    left:0;
                    transform:none;
                }
            }
        }
        
        @include mobile {
            .bg {
                height:100%;
                padding-bottom:0;
            }
        }
        //BTOCSITE-7335 -end
    }
    .KRC0015 .align-top.text-area .text-wrap {
        padding-top: 102px;
    }
    .KRC0015 .box-align-left .text-wrap .inner {
        margin-left: -111px;
    }
    .KRC0015 .title * {
        font-size: 56px;
        line-height: 74px;
        font-weight: 500; // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
    }
    // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
    .no-data {
        margin-top: 12px;
        
        @include mobile {
            padding-bottom: 28px;
        }
        
        p {
            @include pc {
                font-size: 20px;
                line-height: 29px;
            }
        }
    }
    .KRC0015 .visual-area {
        position:relative;
        width:100%;
        img {
            position:absolute;
            top:0;
            left:50%;
            transform:translateX(-50%);
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            max-width:none;
            @include mobile {
                width: 100%;
                height: auto;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    // S : BTOCSITE-47780 닷컴 이벤트&기획전 통합
    &:has(.m-title) {
        .m-title {
            margin: 120px auto 0;
            padding: 0 40px;
            max-width: 1460px;
            font-size: 28px;
            font-weight: 700;
            line-height: 41px;
            &:first-of-type {
                margin-top: 40px;
                @include mobile {
                    margin-top: 24px;
                    padding-top: 0;
                    border: none;
                }
            }
            @include mobile {
                margin-top: 32px;
                padding: 24px 16px 0;
                font-size: 18px;
                line-height: 26px;
                border-top: 7px solid #EAEAEA;
            }
        }
        .KRP0039 .box-list.event-type {
            margin-top: 24px;
            @include mobile {
                margin-top: 16px;
            }
        }
        .evt-main-tab-wrap {
            @include pc {
                margin-top: 8px;
            }
        }
    }
    .KRC0015 {
        .slide-wrap.new-hero-banner {
            .slide-controls {
                display: block;
            }
            .custom-indi-wrap {
                display: flex;
            }
        }
    }
    .evt-main-tab-wrap {
        @include pc {
            padding: 0 40px;
        }
        .tabs-wrap-new {     
            &.expanded {
                .tabs {
                    padding-left: 16px;
                }
            }      
            .tabs {
                @include mobile {
                    padding-left: 0;
                }
                li {
                    @include pc {
                        margin-left: 48px;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                    
                }
            }
        }
        .tabs-2depth {
            max-width: 1380px;
            margin: 0 auto;
            padding: 20px 4px 12px;
            background: #fff;
            @include mobile {
                padding: 9px 0;
            }
        }

        .evt-afilter-choice {
            height: 40px;
            @include mobile {
                height: 30px;
            }
            .evt-afilter-inner {
                display: flex;
                align-items: center;
                position: relative;
                max-width: 1380px;
                margin: 0 auto;
                height: 100%
            }
            p {
                width: calc(100% - 61px);
                padding: 0 16px;
                color: #666;
                font-size: 13px;
            }
            p.choice-base {
                padding: 0;
                display: inline-block;
                width: auto;
                white-space: nowrap;
            }
            .choice-item {
                display: flex;
                align-items: center;
                width: 100%;
                
                .item-reset {
                    width: 40px;
                    @include mobile {
                        position: absolute;
                        right: 0;
                        width: 72px;
                        height: 46px;
                        background-image: linear-gradient(to left, #fff 62%, white 19%, rgba(255, 255, 255, 0));
                        z-index: 1;
                    }
                    button {
                        width: 40px;
                        height: 40px;
                        background: url(https://www.lge.co.kr//lg5-common/images/icons/ico_filter_refresh_24.svg) no-repeat 50% 50%; 
                        background-size: 24px auto;
                        border: 1px solid #8F8F8F;
                        border-radius: 50%;
                        @include mobile {
                            position: absolute;
                            right: 0;
                            width: 72px;
                            height: 100%;
                            background-position: right 16px top 50%;
                            border: none;
                        }
                    }
                    // @include tablet {
                    //     position: relative;
                    //     margin-left: 34px;
                    //     padding-left: 0;
                    //     width: 0;
                    //     height: 44px;
                    //     z-index: 4;
                    //     &::before {
                    //         content: '';
                    //         position: absolute;
                    //         right: -32px;
                    //         top: 0;
                    //         display: block;
                    //         width: 48px;
                    //         height: 100%;
                    //         background: linear-gradient(to left, #fff 57%, rgba(255, 255, 255, 0));
                    //     }
                    //     button {
                    //         position: relative;
                    //         margin-left: 10px;
                    //         margin-top: 10px;
                    //         width: 24px;
                    //         height: 24px;
                    //         background-size: 24px; 
                    //         z-index: 4;
                    //     }
                    // }
                }
            }
            .choice-filter-wrap {
                @include pc {
                    max-width: calc(100% - 60px);
                }
                @include mobile {
                    padding-left: 16px;
                    padding-right: 72px;
                }
                &.swiper {
                    margin: 0;
                    @include pc {
                        max-width: calc(100% - 40px);
                    }
                    // overflow: inherit;
                    // @include tablet {
                    //     width: calc(100% - 38px)!important;
                    // }
                }
            }
            .choice-filter-list {
                height: 40px;
                font-size: 0;
                white-space: nowrap;
                @include mobile {
                    height: 30px;
                }
                li {
                    width: auto;
                }
                button {
                    position: relative;
                    height: 40px;
                    padding: 9px 15px;
                    @include font(14px, 20px);
                    color: #000;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 20px;
                    -webkit-border-radius:20px;
                    @include mobile {
                        height: 30px;
                        padding: 5px 12px;
                        @include font(12px, 18px);
                    }
                    &.on {
                        padding-right: 32px;
                        font-weight: bold;
                        color: #000;
                        border-color: #000;
                        @include mobile {
                            padding-right: 28px;
                        }
                        .close {
                            position: absolute;
                            display: block;
                            top: 12px;
                            right: 8px;
                            width: 16px;
                            height: 16px;
                            background: url(https://www.lge.co.kr//lg5-common/images/icons/btn_delete_filter.svg) no-repeat 50% 50% / 16px auto;
                            @include mobile {
                                top: 9px;
                                right: 12px;
                                width: 12px;
                                height: 12px;
                                background-size: 12px auto;
                            }
                        }
                        @include tablet {
                            // &::after {
                            //     content: '';
                            //     position: absolute;
                            //     top: 7px;
                            //     right: 11px;
                            //     width: 12px;
                            //     height: 12px;
                            //     background-size: 12px auto;
                            // }
                        }
                    }
                }
            }
            .afilter-btn-area {
                position: relative;
                z-index: 1;
                margin-left: auto;
                // width: 61px;
                padding: 8px 16px 8px 10px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -24px;
                    width: 45px;
                    height: 100%;
                    background: linear-gradient(to left,rgb(248,248,248) 57%,rgba(248,248,248,0));
                }
                .btn-afilter {
                    position: relative;
                    z-index: 2;
                    padding: 0;
                    width: 27px;
                    height: 27px;
                    border-radius: 50%;
                    background: #000;
                    overflow: hidden;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        display: block;
                        width: 20px;
                        height: 20px;
                        background: #000 url(/lg5-common/images/icons/btn_afilter_19.svg) no-repeat center;
                        background-size: 100% 100%;
                    }
                    em,
                    span {
                        text-indent: -9999px;
                        opacity: 0;
                    }
                }
                .filter-count {
                    position: absolute;
                    top: 4px;
                    right: 8px;
                    z-index: 3;
                    width: 18px;
                    height: 18px;
                    background-color: #ea1917;
                    color: #fff;
                    font-size: 10px;
                    line-height: 18px;
                    font-weight: 500;
                    text-align: center;
                    border-radius: 50%;
                }
            }
            // &.fixed {
            //     position: fixed;
            //     top: 60px;
            //     left: 0;
            //     z-index: 91;
            //     width: 100%;
            //     max-width: 100%;
            //     & + .plp-new-section-product-list {
            //         padding-top: 56px;
            //     }
            //     @include tablet {
            //         top: 53px;
            //     }
            // }
        }
    }
    .tab-contents {
        padding: 0 0 120px;
        @include mobile {
            padding: 0;
        }
        .component-wrap {
            &:has(.KRP0039) {
                padding-top: 12px;
                @include mobile {
                    padding-top: 8px;
                }
                .KRP0039 {
                    .box-list.event-type {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .list-sorting {
        max-width: 1460px;
        margin: 0 auto;
        padding:28px 40px 12px;	
        display: flex;
        align-items: center;
        @include mobile {
            position: relative;
            margin-top: 44px;
            padding: 16px;
        }
        .sort-area {
            display: flex;
            overflow: hidden;
            &:has(.ui-selectbox-wrap) {
                overflow: visible;
            }
            &:not(.left) {
                margin-left: auto;
            }
            .total-num {
                font-size:14px;
                line-height:20px;
                .number {
                    font-weight:700;
                }
            }
            .sort-list {
                & + .sort-list {
                    margin-left: 20px;
                    @include mobile {
                        margin-left: 12px;
                    }
                }
            }
        }
        .chk-list {
            @include pc {
                margin-top: -1px;
                display: inline-flex;
                vertical-align: top;
            }
            @include mobile {
                position: absolute;
                top: -44px;
                left: 0;
                right: 0;
                padding: 12px 16px 11px;
                border-bottom: 1px solid #ddd;
            }
            .chk-wrap {
                margin-left: 20px;
                @include mobile {
                    margin-left: 0;
                    margin-right: 10px;
                }
                input {
                    &:checked + label {
                        font-weight: 400;
                        &:after {
                            background-image: url(https://www.lge.co.kr//kr/images/BRK/ico-chk-round-on.svg);
                        }
                    }
                    & +label:after {                        
                        background-image: url(https://www.lge.co.kr//kr/images/BRK/ico-chk-round.svg);
                        @include mobile {
                            top: 1px;
                            width: 18px;
                            height: 18px;}
                    }
                    & + label {
                        font-size: 13px;
                        @include mobile {                            
                            padding-left: 22px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        @include mobile {
            margin-bottom:0;
            .sort-area {
                .total-num {
                    font-size:13px;
                }
            }
            .sort-select-wrap {
                margin-left: 12px;
                &:first-child {
                    margin-left: 0;
                }
                .ui-selectbox-wrap {
                    .ui-selectbox-list {
                        @include mobile {
                            left:auto;
                            right:0;
                            transform:none;
                        }
                    }
                    .ui-select-button {
                        padding-right: 20px;
                    }
                }
            }
        }
    }
    .box-list.event-type {
        .box-list-inner {
            @include pc {
                margin: 0 -12px;
            }
            .lists {
                &:nth-child(n+4) {
                    @include pc {
                        margin-top: 38px;
                    }
                }
                @include mobile {
                    margin-top: 32px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
                &.sold-out {
                    .thumb {
                        position: relative;
                    }
                    .dmm {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background:  rgba(0, 0, 0, 0.50);
                        z-index: 1;
                        .txt {
                            font-size: 32px;
                            font-weight: 700;
                            color: #fff;
                            @include mobile {
                                font-size: 24px;
                            }
                        }
                    }
                }
                .list-inner {
                    box-shadow: none;
                    border-radius: 0;
                    a {
                        &:active, &:focus, &:hover {
                            outline-offset: 0;
                        }
                    }
                    .thumb {
                        padding-bottom: 63.74%;
                        border-radius: 16px;
                        overflow: hidden;
                        @include mobile {
                            padding-bottom: 63.72%;
                            border-radius: 12px;
                        }
                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .info {
                        padding: 18px 0 0;
                        @include mobile {
                            padding-top: 12px;
                        }
                        .tit {
                            height: auto;
                            font-size: 18px;
                            line-height: 26px;
                            font-weight: 500;
                            @include textEllipsisClamp(1);
                            @include mobile {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 24px;
                                min-height: 24px;
                                max-height: 48px;
                            }
                        }
                        .date {
                            margin-top: 0px;
                            line-height: 24px;
                            color: #666;
                            @include mobile {
                                margin-top: 2px;
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                    .btn-link {
                        bottom: 0;
                        right: 0;
                        padding-right: 16px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        @include mobile {
                            font-size: 14px;
                            line-height: 20px;
                        }
                        &:after {
                            top: 5px;
                            width: 16px;
                            height: 16px;
                            background-size: 12px auto;
                            @include mobile {
                                top: 3px;
                                background-size: 13px auto;
                            }
                        }
                    }
                }
            }
        }
    }
    // E : BTOCSITE-47780 닷컴 이벤트&기획전 통합
}

@media screen and (max-width: 1920px){
    .event-membership .KRC0015 .box-align-left .text-wrap .inner {
        margin-left: auto;
    }

}

@media screen and (max-width: 767px){
    .event-membership{
        .KRC0015 .align-top.text-area .text-wrap {
            padding:27px 20px 0 20px; // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
        }
        .slide-wrap.hero-banner .slide-content .slide-track, .slide-wrap.hero-banner .slide-content .slide-conts {
            // height: 150px !important; // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
        }
        .component-wrap.bg-white .component .fc-black, .component-wrap.bg-white .component .fc-black p {
            // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
            font-size: 24px; // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
            line-height: 32px;
            br{
                display: none;
            }
            span{
                display: block;
                font-size: 24px; // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
                line-height: 32px;
            }
        }
        .component.KRC0015{
            margin-bottom: 0;
        }
    }
    .KRC0015 .hero-banner .visual-area img.mobile{
        width: 100% !important;
        height:auto !important;
    }
    .sub-main.event-membership {
        padding-bottom: 10px !important;
        border-top:none;
    }
}

@media screen and (max-width: 360px){
    .KRC0015 .hero-banner .visual-area img.mobile{
        height:100% !important;
    }

}

// BTOCSITE-1814 모바일 GNB 멤버십/이벤트 탭 추가 :  신규작성 end
