// BTOCSITE-1814 모바일 GNB 멤버십/이벤트 탭 추가 :  신규작성 start
@charset "UTF-8";
@import "_components";

// .component-wrap {
//     &.zero-btm {
//         @include mobile {
//             padding-bottom:40px !important;
//         }
//     }
// }

.component-wrap {
    .component.KRC0032 {
        .component-box {
            @include mobile {
                padding-bottom:0;
            }
        }
    }
}

.component-wrap {
    &.zero-btm {
        .component.KRC0032 {
            .component-box {
                @include mobile {
                    /* BTOCSITE-1895 : BTOCSITE-1442 원복 요청 */
                    // padding-bottom:60px;
                    /* //BTOCSITE-1895 : BTOCSITE-1442 원복 요청 */
                }
            }
        }
    }
}

.KRC0032 {
	&.stage-full {
        max-width:1920px;
        margin:0 auto;
        .usp-banner-wrap {
            .carousel-box {
                .text-area.type-narrow {
                    .inner {
                        max-width:49.131%;
                        @include mobile {
                            max-width:100%;
                        }
                    }
                }
            }
        }
    }
    &.stage-wide {
        max-width:1600px;
        margin:0 auto;
        // .usp-banner-wrap {
        //     .carousel-box {
        //         .text-area {
        //             padding:0 110px;
        //         }
        //     }
        // }
    }
    &.stage-medium {
        max-width:1380px + 80px;
        margin:0 auto;
        .usp-banner-wrap {
            .carousel-box {
                .text-area {
                    padding:0 117px;
                }
            }
        }
    }
    
    // .component-box {
    //     @include mobile {
    //         padding-bottom:60px;
    //     }
    // }

	.usp-banner-wrap {
		position:relative;
		@include mobile {
            // margin-bottom:40px;
            // padding-bottom:40px;
            &.usp-btm {
                // margin-bottom:0;
                padding-bottom:0;
            }
		}

		// Indicator Area
		.indi-wrap {
            left:50%;
            right:auto;
			bottom:20px;
            z-index:10;
            transform:translateX(-50%);
            @include mobile {
                bottom:0;
            }
		}

		// Swipe Area
		.ui_carousel_track {
			position: relative;
		}
		.carousel-box {
            position:relative;
            .controller-wrap {
                z-index:10;
            }
			.text-area {
				position:absolute;
				top:50%;
                left:40px;
				transform:translateY(-50%);
                @include mobile {
                    left: 17px
                }
				// z-index:1;
				// width:100%;
				// max-width:1380px + 160px;
                // margin:0 auto;
                // padding:0 80px;

				// &.align-top {
				// 	top: 80px;
				// }
				// &.align-middle {
				// 	top: 50%;
				// 	transform:translateX(-50%) translateY(-50%);
				// }
				// &.align-bottom {
				// 	top:auto;
				// 	bottom:80px;
				// }
				// @include mobile {
				// 	position:relative;
				// 	top:0;
				// 	left:auto;
				// 	transform:none;
				// 	max-width:none;
				// 	&.align-top,
				// 	&.align-middle,
				// 	&.align-bottom {
				// 		position:relative;
				// 		top:0;
				// 		left:auto;
				// 		transform:none;
				// 		max-width:none;
				// 		padding:20px 16px 0;
				// 	}
                // }
                
                &.type-wide {
                    .inner {
                        @include boxs(w);
                    }
                }
                &.type-narrow {
                    .inner {
                        // @include boxs(n);
                        max-width:49.131%;
                        @include mobile {
                            max-width:100%;
                        }
                    }
                }

                &.box-align-left {
                    .inner {
                        margin-right:auto;
                        // padding-left:2.5%;
                        // @include mobile {
                        //     padding-left:0;
                        // }
                    }
                }
                &.box-align-center {
                    .inner {
                        margin-left:auto;
                        margin-right:auto;
                    }
                }
                &.box-align-right {
                    .inner {
                        margin-left:auto;
                    }
                }
                &.text-align-left {
                    text-align:left;
                }
                &.text-align-center {
                    text-align:center;
                }
                &.text-align-right {
                    text-align:right;
                }
                
				.banner-tit-sup {
                    display:block;
                    @include eyebrow;
				}
				.title {
                    h2 {
                        @include title-h2;
                    }
                    &.title-h1 * {
                        @include title-h1;
                    }
                    &.title-h2 * {
                        @include title-h2;
                    }
                    &.title-h3 * {
                        @include title-h3;
                    }
                    &.title-h4 * {
                        @include title-h4;
                    }
                    &.title-h5 * {
                        @include title-h5;
                    }
                    &.title-h6 * {
                        @include title-h6;
                    }
				}
				.banner-txt {
					// @include bodycopy(h1, h1);
                    margin-top: 4px;
                    font-size: 16px;
                    line-height: 24px;
                    @include mobile {
                        font-size: 12px !important;
                        line-height: 18px !important;
                        br.mo-only {
                            display: block
                        }
                    }
				}
				.banner-btn {
                    @include cta-btn;
                    @include mobile {
                        .btn.border {
                            // display:block;
                        }
                    }
                }
                .overlay-image {
                    max-width:678px;
                    margin-top:24px;
                    overflow:hidden;
                    &:first-child {
                        margin-top:0;
                    }
                    img {
                        width:auto;
                        max-width:100%;
                        height:auto;
                        vertical-align:top;
                    }
                }
			}
            .video-link {
                position:absolute;
                left:50%;
                bottom:68px;
                transform:translateX(-50%);
                z-index:20;
                @include mobile {
                    bottom:24px;
                }
            }
            .video-asset {
                z-index:20;
            }
			.visual-area {
				position:relative;
                width:100%;
                text-align:center;
                overflow:hidden;
                img {
                    width:auto;
                    max-width:100%;
                    height:auto;
                    vertical-align:top;
                }
			}
		}

		// Navigation Area
		.controls-wrap {
            position:absolute;
            top:50%;
            left:0;
            right:0;
            z-index:10;
            .inner {
                padding:0 16px;
                max-width:1380px + 144px + 32px;
                margin:0 auto;
                @include mobile {
                    padding:0 8px;
                }
                .row {
                    position:relative;
                    margin:0 !important;
                    .btn-arrow {
                        position:absolute;
                        top:0;
                        transform:translateY(-50%);
                        &.prev {
                            left:0;
                        }
                        &.next {
                            right:0;
                        }
                    }
                }
            }
            @at-root .KRC0032 {
                &.stage-medium {
                    .usp-banner-wrap {
                        .ui_carousel_list {
                            max-width:1380px;
                            margin:0 auto;
                        }
                    }
                }
            }
        }
	}
    .fc-white,
    .fc-light-gray {
        & {
            @include mobile {
                color:#000 !important;
            }
        }
        .logo {
            filter:brightness(0) invert(1);
            @include mobile {
                filter:brightness(1) invert(0) !important;
            }
        }
    }
    // .fc-black,
    // .fc-dark {
    //     & {
    //         @include mobile {
    //             color:#fff !important;
    //         }
    //     }
    //     .logo {
    //         filter:brightness(1) invert(0);
    //         @include mobile {
    //             filter:brightness(0) invert(1);
    //         }
    //     }
    // }
}


.component-wrap {
    $color: (lightgray, dark, black, white, gray);
    @each $bg in $color {
        &.bg-#{$bg} {
            .component.KRC0032 {
                @include mobile {
                    @if $bg == lightgray {
                        .fc-black,
                        .fc-dark-gray,
                        .fc-gray,
                        .fc-light-gray,
                        .fc-white {
                            color:#000 !important;
                            * {
                                color:#000 !important;
                            }
                        }
                        .btn.border {
                            &.bc-black,
                            &.bc-white {
                                border-color:#222 !important;
                                color:#000 !important;
                                &:hover,
                                &:active,
                                &:focus {
                                    border-color:#fa263e !important;
                                    background-image: linear-gradient(280deg, #fb5a72, #fa263e) !important;
                                    color:#fff !important;
                                }
                            }
                        }
                        .text-link {
                            &.bc-black,
                            &.bc-white {
                                color:#000 !important;
                                &::after {
                                    background:url("/lg5-common/images/icons/icon-arr-16-bk.svg") center no-repeat;
                                }
                            }
                        }
                    } @else if $bg == dark {
                        .fc-black,
                        .fc-dark-gray,
                        .fc-gray,
                        .fc-light-gray,
                        .fc-white {
                            color:#fff !important;
                            * {
                                color:#fff !important;
                            }
                        }
                        .btn.border {
                            &.bc-black,
                            &.bc-white {
                                border-color:#fff !important;
                                color:#fff !important;
                                &:hover,
                                &:active,
                                &:focus {
                                    border-color:#fa263e !important;
                                    background-image: linear-gradient(280deg, #fb5a72, #fa263e) !important;
                                    color:#fff !important;
                                }
                            }
                        }
                        .text-link {
                            &.bc-black,
                            &.bc-white {
                                color:#fff !important;
                                &::after {
                                    background:url("/lg5-common/images/icons/icon-arr-16-wt.svg") center no-repeat;
                                }
                            }
                        }
                    } @else if $bg == black {
                        .fc-black,
                        .fc-dark-gray,
                        .fc-gray,
                        .fc-light-gray,
                        .fc-white {
                            color:#fff !important;
                            * {
                                color:#fff !important;
                            }
                        }
                        .btn.border {
                            &.bc-black,
                            &.bc-white {
                                border-color:#fff !important;
                                color:#fff !important;
                                &:hover,
                                &:active,
                                &:focus {
                                    border-color:#fa263e !important;
                                    background-image: linear-gradient(280deg, #fb5a72, #fa263e) !important;
                                    color:#fff !important;
                                }
                            }
                        }
                        .text-link {
                            &.bc-black,
                            &.bc-white {
                                color:#fff !important;
                                &::after {
                                    background:url("/lg5-common/images/icons/icon-arr-16-wt.svg") center no-repeat;
                                }
                            }
                        }
                    } @else if $bg == white {
                        .fc-black,
                        .fc-dark-gray,
                        .fc-gray,
                        .fc-light-gray,
                        .fc-white {
                            color:#000 !important;
                            * {
                                color:#000 !important;
                            }
                        }
                        .btn.border {
                            &.bc-black,
                            &.bc-white {
                                border-color:#222 !important;
                                color:#000 !important;
                                &:hover,
                                &:active,
                                &:focus {
                                    border-color:#fa263e !important;
                                    background-image: linear-gradient(280deg, #fb5a72, #fa263e) !important;
                                    color:#fff !important;
                                }
                            }
                        }
                        .text-link {
                            &.bc-black,
                            &.bc-white {
                                color:#000 !important;
                                &::after {
                                    background:url("/lg5-common/images/icons/icon-arr-16-bk.svg") center no-repeat;
                                }
                            }
                        }
                    } @else if $bg == gray {
                        .fc-black,
                        .fc-dark-gray,
                        .fc-gray,
                        .fc-light-gray,
                        .fc-white {
                            color:#000 !important;
                            * {
                                color:#000 !important;
                            }
                        }
                        .btn.border {
                            &.bc-black,
                            &.bc-white {
                                border-color:#222 !important;
                                color:#000 !important;
                                &:hover,
                                &:active,
                                &:focus {
                                    border-color:#fa263e !important;
                                    background-image: linear-gradient(280deg, #fb5a72, #fa263e) !important;
                                    color:#fff !important;
                                }
                            }
                        }
                        .text-link {
                            &.bc-black,
                            &.bc-white {
                                color:#000 !important;
                                &::after {
                                    background:url("/lg5-common/images/icons/icon-arr-16-bk.svg") center no-repeat;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// KRC0032를 html로만 구현 적용부분 start
.KRC0032-html-membership .usp-banner-wrap .carousel-box .visual-area img{
    border-radius:8px;   
}
.KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area.align-top{
    top:21.25%;
}
.KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area{
    @include mobile {
        // position: absolute !important;
        // top:0 !important; 
        // left:0 !important;
        // padding-top:34px !important;
    }
}

.KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area.box-align-center .inner {
    margin-left: 97px !important;
    @include mobile {
        margin-left: auto !important;
    }
}

.KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .title h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    @include mobile {
        font-size: 14px;
        line-height: 20px;
     }
}
.KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-txt {
    // BTOCSITE-47780 닷컴 이벤트&기획전 통합
    font-size: 16px;
    line-height: 23px;
    margin-top: 4px;
    @include mobile {
        font-size: 15px;
        line-height: 1.53;
        // margin-top: 11px;
        // text-align: center;
     }
}
.KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-btn {
    margin-top: 40px;
    font-size: 0;
    @include mobile {
        text-align: center;
        margin-top: 21px;
     }
    .btn.border{
        padding:14px 48px 14px 35px !important;
        border-radius: 30px !important;
        @include mobile {
            padding:8px 46px 9px 38px !important;
            border-radius: 23px !important;
         }
        span{
            font-size:18px !important;
            font-weight:700 !important;
            @include mobile {
                font-size:14px !important;
                line-height:14px;
             }
        }
    }
    .btn.border.arrow:after {
        margin-top: -10px;
        @include mobile {
          margin-top: -9px;
         }
    }
}

.KRC0032-html-banner-wrap{
    padding: 0; //  BTOCSITE-47780 닷컴 이벤트&기획전 통합
}

.KRC0032-html-banner .usp-banner-wrap .carousel-box .visual-area img{
    border-radius:8px;   
}
.KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area{
    // top:26.5%;
}
.KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area{
    @include mobile {
        // position: absolute !important;
        // top:0 !important; 
        // left:0 !important;
        // padding:18px 0 0 0 !important;
    }
}


.KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .title h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    @include mobile {
        font-size: 14px;
        line-height: 20px;
     }
}
// S : BTOCSITE-47780 닷컴 이벤트&기획전 통합
.KRC0032-html-wrap {
    margin: 0 auto;
    max-width: 1460px;
    padding: 0 40px 120px;
    display: flex;
    gap: 24px;
    @include mobile {
        margin-top: 32px;
        border-top: 7px solid #F3F3F3;
    }
    & > .component-wrap {
        flex: 1;
        img {
            width: 100%;
        }
    }
    a {
        &:active, &:focus, &:hover {
            outline-offset: 0;
        }
    }
}
// E : BTOCSITE-47780 닷컴 이벤트&기획전 통합

@media screen and (max-width: 1400px){
    
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area.box-align-center .inner {
    //     margin-left: 2% !important;
    // }
    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .inner {
    //     margin-left: 45% !important;
    // }
}

@media screen and (max-width: 1220px){
    .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-btn {
        margin-top: 25px;        
    }
    .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .title h2{
        // font-size: 26px;
        // line-height: 37px;
    }
}

@media screen and (max-width: 1150px){
    .KRC0032-html-wrap{
        padding: 24px 16px 50px;
        display: block;
        gap: 0;
        & > .component-wrap {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .title h2 {
    //     font-size: 36px;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-txt {
    //     font-size: 18px;
    //     line-height: 22px;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-btn .btn.border {
    //     padding: 8px 46px 9px 38px !important;
    //     border-radius: 23px !important;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-btn .btn.border span {
    //     font-size: 14px !important;
    //     line-height: 14px;
    // }
    // .KRC0032-html-banner-wrap {
    //     padding-top: 30px; // BTOCSITE-66753 닷컴 이벤트/멤버십 홈 컨텐츠 수정 반영 요청
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-btn {
    //     margin-top: 25px;        
    // }
    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .title h2{
    //     font-size: 22px;
    //     line-height: 35px;
    // }
}

@media screen and (max-width: 910px){
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area.box-align-center .inner {
    //     margin-left: 0 !important;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area.align-top {
    //     top: 14.25%;
    // }
    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .title h2 {
    //     font-size: 18px;
    //     line-height: 29px;
    // }
}
@media screen and (max-width: 768px) {
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area.align-top {
    //     top: 10.25%;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .title h2 {
    //     font-size: 33px;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-txt {
    //        margin-top: 8px;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-btn {
    //     margin-top: 15px;
    // }
}
@media screen and (max-width: 767px) {
    // .KRC0032.KRC0032-html-membership .usp-banner-wrap {
    //     padding-bottom: 0 !important;
    // }
    // .KRC0032.KRC0032-html-banner .usp-banner-wrap {
    //     padding-bottom: 0 !important;
    // }
    // .component-wrap.zero-btm .component.KRC0032.KRC0032-html-membership .component-box {
    //     padding-bottom: 0 !important;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area{
    //     position: absolute !important;
    //     top:0 !important; 
    //     left:0 !important;
    //     padding-top:34px !important;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area.box-align-center .inner {
    //     margin-left: auto !important;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .title h2 {
    //     font-size: 27px !important;
    //     line-height: 38px !important;
    //     text-align: center;
    //     padding-left:30px; 
    //     padding-right:30px;
    //  }
    //  .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-txt {
    //     font-size: 15px !important;
    //     line-height: 1.53 !important;
    //     margin-top: 11px;
    //     text-align: center;
    // }
    // .KRC0032-html-membership .usp-banner-wrap .carousel-box .text-area .banner-btn {
    //     text-align: center;
    //     margin-top: 21px;    
    //     .btn.border{ 
    //         padding:8px 46px 9px 38px !important;
    //         border-radius: 23px !important;   
    //         span{
    //             font-size:14px !important;
    //             line-height:14px;  
    //         }
    //     }
    //     .btn.border.arrow:after {
    //           margin-top: -9px;
    //     }
    // }
    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area{
    //     position: absolute !important;
    //     position: absolute !important;
    //     top:50% !important; 
    //     right:26px !important;
    //     left:auto !important;
    //     width:39% !important;
    //     height:100px !important;
    //     padding:0 !important;
    //     margin-top:-50px;
    // }
    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .inner {
    //     margin-left: auto !important;
    //     position: relative;
    //     width:100%; 
    //     height:100%;
    // }
    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .inner .text-block{
    //    width:100%; 
    //     height:100%;
    // }
    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .title{
    //     width:100%; 
    //     height:100%;
    //     display:table;
    // }

    // .KRC0032-html-banner .usp-banner-wrap .carousel-box .text-area .title h2 {
    //     font-size: 14px !important;
    //     line-height: 22px !important;
    //     width:100%; 
    //     height:100%;
    //     display:table-cell;
    //     vertical-align: middle;
    //     text-align: left;
    //     br{
    //         display: block;
    //     }
    //     span{
    //         display:block;
    //         font-size: 14px !important;
    //         line-height: 22px !important;
    //     }
    //     .KRC0032-html-banner-wrap {
    //         padding-bottom: 0 !important;
    //     }
    // }
}
// KRC0032를 html로만 구현 적용부분 end

// BTOCSITE-1814 모바일 GNB 멤버십/이벤트 탭 추가 :  신규작성 end