// BTOCSITE-1814 모바일 GNB 멤버십/이벤트 탭 추가 :  신규작성 start
@charset "UTF-8";
@import "_components";

.KRP0039 {
    .component-inner { 
        .evt-bnr {
            @include clearfix;
            .tit {
                font-size:32px;
                line-height:42px;
                font-weight:700;
                text-align:center;
                @include textEllipsisClamp(2);
                @include mobile {
                    font-size:24px;
                    line-height:33px;
                }
            }        
        }
    }
    .box-list.event-type {
        margin-top:49px;
        @include mobile {
            margin-top:40px;
        }
        // BTOCSITE-5938-185 s
        .box-list-inner .lists .list-inner {
            height: auto;
        } 
        // BTOCSITE-5938-185 e
		.box-list-inner .lists .list-inner .info {
            .tit {
                @include textEllipsisClamp(2);
                // BTOCSITE-5938-185 s
                // min-height:59px;
                // max-height:59px;
                display: block;
                height:59px;
                // BTOCSITE-5938-185 e
                text-align:left;
                @include mobile {
                    // BTOCSITE-5938-185 
                    height:auto;
                    min-height:23px;
                    max-height:47px;
                }
            }
            .date {                
                @include mobile {
                    height: 16px;
                }
            }

        } 
	}
    .btn-area{
        text-align: center;
        margin-top:32px;
        margin-bottom:40px;
        @include mobile {
            margin-bottom: 0;
        }
        .btn.border.size-m {
            @include mobile {
                padding: 12px 20px !important;
                border-radius: 21px !important;
            }
        }
    }
}
// BTOCSITE-1814 모바일 GNB 멤버십/이벤트 탭 추가 :  신규작성 end