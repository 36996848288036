@charset "UTF-8";
@import "_components";

* { //BTOCSITE-28336 전체 카피, 카피문구 줄내림 형식 keep-all로 변경
	word-break: keep-all !important;
}

.KRC0015 {
    width: 100%;

    .component-box {
        max-width: 1920px;
        margin: 0 auto;
    }

    &.stage-full {
		.component-box {
			max-width:1920px !important;
		}
    }
    &.stage-wide {
		.component-box {
			max-width:1660px !important;
		}
    }
    &.stage-medium {
		.component-box {
			max-width:1380px !important;
		}
    }
    
    .title * {
        @include title-h1;
    }

    .slide-wrap {
        .custom-indi-wrap {
            bottom: 24px;
            right: 40px;
            left: 40px;
            max-width: 1380px;
            margin: 0 auto;
            .custom-indis {
                max-width: none;
                margin: 0 -15px;
                .indis {
                    width: 100%;
                    table-layout: fixed;
                    li {
                        width: auto;
                        padding: 0 15px;
                        &.on {
                            width: auto;
                        }
                    }
                }
            }
            .indi-control {
                position: absolute;
                bottom: 16px;
                right: 0;
                margin: 0;
            }
            @include mobile {
                bottom: 20px;
                right: 16px;
                left: 16px;
                max-width: none;
                .custom-indis {
                    margin: 0 -5px;
                    .indis {
                        li {
                            padding: 0 5px;
                        }
                    }
                }
                .indi-control {
                    bottom: 20px;
                }
            }
        }
        .slide-controls {
            display: none;
        }
        .ui_carousel_list {
            overflow:hidden;
        }
        .slide-conts {
            height: auto;
        }

        @include mobile {
            &.hero-banner .slide-content .slide-conts {
                height: 530px;
            }
        }
    }

    .controller-wrap {
        margin-left: 32px;
    }

    .visual-area {
        overflow: hidden;
        height: 100%;
        text-align:center;
        img {
            position: relative;
            width:auto;
            max-width:100%;
            height:auto;
            vertical-align:top;
            /* 클래스별이미지분기 */
            &.pc{
                display:inline-block;
            }
            &.mobile{
                display:none;
            }
            @include mobile {
                &.pc{
                    display:none;
                }
                &.mobile{
                    display:inline-block;
                }
            }
            /* //클래스별이미지분기 */
        }
    }
    .hero-banner {
        .visual-area {
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-width: none;
            }
            .animation-area {
                height: 100%;
                video {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: 1;
                    @include mobile {
                        width: auto;
                    }
                }
            }
        }
    }

    .contents-area {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        .text-area {
            position: relative;
            z-index: 1;
            .text-wrap {
                width: 100%;
                max-width: (1380px + 80px);
                margin: 0 auto;
                padding-right: 40px;
                padding-left: 40px;

                @include mobile {
                    max-width: 100%;
                    padding-right: 16px;
                    padding-left: 16px;
                }
            }
        }
    }

    .video-asset {
        z-index: 2;
    }

    .text-block {
        .eyebrow {
            @include eyebrow;
        }
        .copy {
            @include bodycopy(h1, h2);
        }
    }

    .cta {
        margin-top:24px;
    }
    .cta-button,
    .cta-link {
        display:inline-block;
        margin-top:0;
        font-size:0;
        vertical-align:middle;
        .btn.border,
        .text-link {
            margin-left:16px;
            &:first-child {
                margin-left:0;
            }
        }
    }
    .video-link {
        display:inline-block;
        margin-left:32px;
        vertical-align:middle;
    }

    @include mobile {
        .cta-link {
            & + .video-link {
                display: block;
                margin-top: 12px;
                margin-left: 0;
            }
        }
    }

    .type-wide {
        .text-wrap {
            .inner {
                @include boxs(w);
            }
        }
    }
    .type-narrow {
        .text-wrap {
            .inner {
                @include boxs(n);
            }
        }
    }

    // 텍스트 가로정렬 및 버튼 사이 간격
    $align: (left, right, center, top, middle);
    @each $key in $align {
        .box-align-#{$key} {
            @if $key == left {
                .text-wrap {
                    .inner {
                        margin-right:auto;
                    }
                }
            }
            @if $key == right {
                .text-wrap {
                    .inner {
                        margin-left:auto;
                    }
                }
            }
            @if $key == center {
                .text-wrap {
                    .inner {
                        margin-left:auto;
                        margin-right:auto;
                    }
                }
            }
        }
        .align-#{$key} {
            @if $key == top {
                &.text-area {
                    height: 100%;
                    .text-wrap {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        padding-top: 80px;
                        @include mobile {
                            padding-top: 32px;
                        }
                    }
                }
            }
            @if $key == middle {
                &.text-area {
                    height: 100%;
                    .text-wrap {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        left: 0;
                        transform:translateY(-50%);
                    }
                }
            }
        }
    }

    .disclaimer-wrap {
        display: none;
    }
}
